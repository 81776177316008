import React from "react";
import Layout from '../../components/Layout'
import Table from 'react-bootstrap/Table';


const StatisticsPage = () => {
    const routes = [
        {
            id: 1,
            name: "Ruta 1 (Almendros y Azulejos)",
            aluminum: 53,
            iron: 54.4,
            cardboard: 645.9,
            pet: 532.9,
            glass: 1074.5
        },
        {
            id: 2,
            name: "Ruta 2 (Villas la Rosita)",
            aluminum: 13.3,
            iron: 22,
            cardboard: 281.1,
            pet: 275.7,
            glass: 405.5
        }
    ];
    return (   
        <Layout>
            <section className="section">
                <div className="container">
                    <div className="content">
                        <p>
                            En esta sección podrá encontrar las estadísticas de recolección de Reciclapp.
                        </p>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Rutas</th>
                                    <th>Aluminio (kg)</th>
                                    <th>Lámina Fierro (kg)</th>
                                    <th>Cartón (kg)</th>
                                    <th>PET (kg)</th>
                                    <th>Vidrio (kg)</th>
                                    <th>Total (kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {routes.map((route) => (
                                    <tr key={route.id}>
                                        <td>{route.name}</td>
                                        <td>{route.aluminum}</td>
                                        <td>{route.iron}</td>
                                        <td>{route.cardboard}</td>
                                        <td>{route.pet}</td>
                                        <td>{route.glass}</td>
                                        <td>{route.aluminum + route.iron + route.cardboard + route.pet + route.glass}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <p>Última fecha de actualización: 24 de septiembre de 2022</p>
                    </div>
                </div>
            </section>           
        </Layout>
    )

}
export default StatisticsPage